.customCard {
    background-repeat: no-repeat;
    border-radius: 8px;
    background-color: #008ED1!important;
    color: #fff;
    padding: 2rem;
    text-align: left;
    height: 42vh;
  }

  .youtube-container iframe {
    width: 85vw;
    height: 80vh;
    background: transparent;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  }
  .monte-carlo-chart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 80px);
    background-color: rgba(0, 142, 209, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  /* @media (max-width: 390px) {
    .monte-carlo-chart {
      height: 155vh;
    }
  } */


  /* @media (min-width: 500px) {
    .monte-carlo-chart {
      height: 109vh;
    }
    .monte-carlo-paper {
        height: 109vh;
      }
  } */

  .monte-carlo-paper {
    padding: 0;
    height: 92%;
    margin-top: 12px;
}

.cladogram-wrapper {
    width: 1200px;
    height: 36vh
  }

  @media only screen and (max-width: 560px) {
    .cladogram-wrapper {
        width: 380px;
        height: 40vh
      }
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    .cladogram-wrapper {
        width: 800px;
        height: 38vh
      }
  }

/* @media only screen and (max-width: 360px) {
    .monte-carlo-paper {
        height: 125vh;
      }
  }

  @media (min-width: 411px) and (max-width: 499px) {
    .monte-carlo-chart, .monte-carlo-paper {
        height: 120vh;
    }
} */

  /* @media (min-width: 390px) and (max-width: 410px) {
    .monte-carlo-chart, .monte-carlo-paper {
        height: 122vh;
    }
} */

/* @media (min-width: 350px) and (max-width: 390px) {
    .monte-carlo-chart, .monte-carlo-paper {
        height: 128vh;
    }
} */

/*
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    .monte-carlo-paper {
        height: 102vh;
      }
      .monte-carlo-chart {
        height: 122vh;
      }
  } */



