.nav-link {
    color: #000;
    display: flex;
  }

  .nav-link.active span {
    color: #008ED1;
    background-color: transparent;
  }

  .nav-link.active svg path {
    stroke: #008ED1;
  }
